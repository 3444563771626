var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("emc-loader-progress", {
        attrs: { text: "Aguarde...", show: _vm.loading }
      }),
      _vm.message
        ? _c(
            "v-card",
            { staticClass: "mx-auto my-12", attrs: { "max-width": "450" } },
            [
              _c(
                "v-app-bar",
                { attrs: { dark: "", color: _vm.error ? "error" : "success" } },
                [
                  _c(
                    "v-toolbar-title",
                    [
                      _c("v-icon", [_vm._v("mdi-check-all")]),
                      _vm._v(" Confirmação de email")
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", { staticClass: "text-center text-body-1" }, [
                _vm._v(" " + _vm._s(_vm.message) + " ")
              ]),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c("div", { staticClass: "caption text-right mr-3" }, [
                    _vm._v("Nossas redes sociais: ")
                  ]),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        href: "https://www.instagram.com/emcsoft"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "pink--text text--lighten-2" },
                        [_vm._v("mdi-instagram")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        href:
                          "https://www.linkedin.com/company/emcsoft-sysclass"
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "blue--text text--darken-3" },
                        [_vm._v("mdi-linkedin")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }